@import url(../fonts/lato/stylesheet.css);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

ul {
  list-style: none; }

a,
a:hover {
  text-decoration: none; }

input,
button,
textarea,
select {
  outline: none !important; }

body {
  background: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 16px;
  line-height: 18px;
  position: relative;
  -webkit-font-smoothing: antialiased; }
  @media (max-width: 575.98px) {
    body {
      font-size: 12px;
      line-height: 13px; } }

/* Fancybox */
.fancybox-thumbs__list,
.fancybox-button--thumbs {
  display: none; }

.prev-btn img {
  width: 75px;
  height: 75px;
  transform: rotate(180deg); }

.next-btn img {
  width: 75px;
  height: 75px; }

.btn {
  transition: all 0.5s;
  background-color: transparent; }
  .btn:hover {
    background: linear-gradient(#ff9800, #ff9800);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); }

.wrapper {
  overflow: hidden; }

.container {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px; }

.popup-call, .popup-review {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: none; }

.popup-inner {
  position: relative;
  padding: 50px 100px 50px 97px;
  background: #fff;
  width: 553px;
  height: auto;
  border-radius: 10px; }
  @media (max-width: 575.98px) {
    .popup-inner {
      padding: 25px 30px;
      width: 320px; } }

.popup-title h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 34px;
  line-height: 40px;
  font-weight: 800;
  font-style: italic;
  margin-bottom: 15px; }
  @media (max-width: 575.98px) {
    .popup-title h2 {
      font-size: 28px;
      line-height: 30px; } }

.popup-subtitle {
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 30px; }
  @media (max-width: 575.98px) {
    .popup-subtitle {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 15px; }
      .popup-subtitle br {
        display: none; } }

.popup-form__input {
  display: block;
  width: 100%;
  height: 54px;
  border: 1px solid #ffb017;
  border-radius: 10px;
  margin-bottom: 22px;
  padding: 16px;
  font-size: 16px;
  line-height: 18px;
  color: #000;
  font-weight: 400; }
  @media (max-width: 575.98px) {
    .popup-form__input {
      height: 47px; } }
  .popup-form__input::placeholder {
    font: 400px 16px "Lato";
    color: #000;
    font-size: 16px;
    line-height: 18px; }

.popup-form__textarea {
  resize: none;
  min-height: 195px;
  margin-bottom: 45px;
  font: 400 16px "Lato";
  color: black; }
  @media (max-width: 575.98px) {
    .popup-form__textarea {
      margin-bottom: 25px;
      min-height: 120px; } }
  .popup-form__textarea::placeholder {
    font: 400 16px "Lato";
    color: black; }

.popup-form__btn {
  width: 100%;
  text-align: center;
  height: 65px;
  cursor: pointer;
  border: none;
  color: #000;
  font-weight: 700;
  padding: 17px 10px 15px;
  background: linear-gradient(#ffe711, #ff9b11);
  border-radius: 5px;
  display: inline-block;
  font-size: 24px;
  margin-bottom: 13px; }
  @media (max-width: 575.98px) {
    .popup-form__btn {
      height: 48px;
      font-size: 14px;
      padding: 10px 10px 12px; } }

.popup-form__text {
  max-width: 320px;
  width: 100%; }
  .popup-form__text a {
    color: #000;
    text-decoration: underline; }

.popup-close {
  width: 75px;
  height: 75px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: -25px;
  right: -25px;
  cursor: pointer; }
  @media (max-width: 575.98px) {
    .popup-close {
      width: 45px;
      height: 45px;
      top: -15px;
      right: -15px; }
      .popup-close img {
        width: 35px;
        height: 35px; } }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 8;
  display: none; }

.scroll-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 75px;
  height: 54px;
  background: #345EDB;
  border-bottom-right-radius: 5px;
  padding-top: 13px;
  padding-left: 23px;
  z-index: 4;
  display: none;
  cursor: pointer; }
  .scroll-menu svg {
    width: 25px; }

@media (max-width: 1199.98px) {
  .title {
    text-align: center; } }

.title h2 {
  font-size: 30px;
  line-height: 31px;
  font-weight: 700;
  border-bottom: 6px solid #ffb017;
  display: inline-block;
  padding-bottom: 3px;
  margin-bottom: 37px; }
  @media (max-width: 575.98px) {
    .title h2 {
      font-size: 20px;
      line-height: 21px;
      margin-bottom: 33px;
      border-bottom: 4px solid #ffb017; } }

.header img {
  display: block; }

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; }
  @media (max-width: 991.98px) {
    .header-top {
      flex-direction: column; } }
  @media (max-width: 575.98px) {
    .header-top {
      margin-bottom: 25px; } }
  .header-top .top-wrapper {
    display: flex;
    max-width: 390px;
    width: 100%;
    justify-content: space-between; }
    @media (max-width: 991.98px) {
      .header-top .top-wrapper {
        order: 2;
        margin-bottom: 20px; } }
    @media (max-width: 575.98px) {
      .header-top .top-wrapper {
        max-width: 276px; } }
  .header-top__width {
    display: flex;
    align-items: center; }
    .header-top__width-icon {
      margin-right: 14px;
      width: 52px; }
      @media (max-width: 575.98px) {
        .header-top__width-icon {
          width: 36px;
          margin-right: 10px; } }
    .header-top__width-text span {
      display: block;
      font-weight: 700; }
    @media (max-width: 575.98px) {
      .header-top__width-text {
        font-size: 12px;
        line-height: 13px; } }
  .header-top__work {
    display: flex;
    align-items: center; }
    .header-top__work-icon {
      margin-right: 14px;
      width: 33px; }
      @media (max-width: 575.98px) {
        .header-top__work-icon {
          width: 24px;
          margin-right: 10px; } }
    @media (max-width: 575.98px) {
      .header-top__work-text {
        font-size: 12px;
        line-height: 13px; } }
  .header-top__middle {
    max-width: 225px;
    width: 100%;
    min-height: 224px;
    text-align: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 33px; }
    @media (max-width: 991.98px) {
      .header-top__middle {
        order: 1;
        margin-bottom: 30px; } }
    @media (max-width: 575.98px) {
      .header-top__middle {
        max-width: 166px;
        min-height: 171px;
        padding-top: 20px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px; } }
    .header-top__middle img {
      margin: 0 auto; }
    .header-top__middle-logo {
      margin-bottom: 18px !important;
      width: 161px; }
      @media (max-width: 575.98px) {
        .header-top__middle-logo {
          width: 118px; } }
    .header-top__middle-text {
      margin-bottom: 7px;
      color: #000; }
      @media (max-width: 575.98px) {
        .header-top__middle-text {
          font-size: 12px;
          line-height: 13px; } }
    .header-top__middle-ever {
      width: 202px;
      transform: rotate(-3deg);
      display: block;
      padding-bottom: 5px; }
      @media (max-width: 575.98px) {
        .header-top__middle-ever {
          width: 149px; } }
  .header-top__contact {
    display: flex;
    align-items: center; }
    @media (max-width: 991.98px) {
      .header-top__contact {
        order: 3;
        margin-bottom: 15px; } }
    .header-top__contact-icon {
      margin-right: 13px;
      width: 29px; }
    .header-top__contact-phone {
      display: block;
      font-size: 24px;
      line-height: 26px;
      font-weight: 900;
      color: #000; }
      .header-top__contact-phone .phone-span {
        display: none; }
        @media (max-width: 991.98px) {
          .header-top__contact-phone .phone-span {
            display: inline-block;
            vertical-align: middle; } }
      .header-top__contact-phone span {
        font-weight: 700;
        display: block;
        font-size: 16px;
        line-height: 18px; }
        @media (max-width: 991.98px) {
          .header-top__contact-phone span {
            display: none; } }
  .header-top__order {
    text-align: center; }
    @media (max-width: 991.98px) {
      .header-top__order {
        order: 4; } }
    .header-top__order-btn {
      width: 207px;
      text-align: center;
      text-transform: uppercase;
      color: #000;
      font-weight: 700;
      height: 48px;
      padding: 17px 10px 15px;
      background: linear-gradient(#ffe711, #ff9b11);
      border-radius: 3px;
      display: inline-block;
      margin-bottom: 10px; }
      @media (max-width: 575.98px) {
        .header-top__order-btn {
          width: 146px;
          font-size: 12px !important;
          line-height: 13px !important;
          height: 34px;
          padding: 12px 10px;
          margin-bottom: 7px; } }
    @media (max-width: 575.98px) {
      .header-top__order-text {
        font-size: 12px;
        line-height: 13px; } }

.header-nav {
  display: flex;
  justify-content: space-between;
  border-bottom: 3px solid #3960eb; }
  @media (max-width: 1199.98px) {
    .header-nav {
      justify-content: center; } }
  @media (max-width: 991.98px) {
    .header-nav {
      border-bottom: none;
      background: #eeeeee;
      border-radius: 3px;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
      margin-bottom: 20px; } }
  .header-nav__mobile {
    display: none; }
    @media (max-width: 991.98px) {
      .header-nav__mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 15px;
        cursor: pointer; } }
    @media (max-width: 575.98px) {
      .header-nav__mobile {
        padding: 12px 15px; } }
    .header-nav__mobile p {
      font-size: 22px;
      font-weight: 700;
      margin-right: 6px; }
      @media (max-width: 575.98px) {
        .header-nav__mobile p {
          font-size: 15px;
          line-height: 16px;
          margin-top: 2px; } }
  .header-nav .mobile-menu {
    width: 25px; }
    @media (max-width: 575.98px) {
      .header-nav .mobile-menu {
        width: 13px; } }
  .header-nav__menu {
    display: flex;
    padding-left: 25px; }
    @media (max-width: 1199.98px) {
      .header-nav__menu {
        padding-left: 0;
        padding-bottom: 17px; } }
    @media (max-width: 991.98px) {
      .header-nav__menu {
        position: fixed;
        top: 0;
        left: -100%;
        display: block;
        width: 300px;
        background: #0061f3;
        height: 100%;
        padding-top: 45px;
        padding-right: 15px;
        z-index: 5; }
        .header-nav__menu .menu-close {
          display: flex;
          width: 33px;
          height: 33px;
          background: #fff;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          margin-left: auto;
          cursor: pointer;
          margin-bottom: 27px;
          z-index: 5; }
          .header-nav__menu .menu-close img {
            width: 26px;
            height: 26px; } }
    @media (max-width: 575.98px) {
      .header-nav__menu {
        width: 215px; } }
    .header-nav__menu .menu-social {
      display: none; }
      @media (max-width: 991.98px) {
        .header-nav__menu .menu-social {
          max-width: 115px;
          margin: 45px auto 0;
          display: flex;
          justify-content: space-between;
          align-items: center; } }
      .header-nav__menu .menu-social svg {
        width: 23px;
        color: #fff; }
      .header-nav__menu .menu-social .first-svg {
        width: 32px;
        color: #fff; }
    .header-nav__menu .menu-close {
      display: none; }
  .header-nav__list:not(:last-child) {
    margin-right: 46px; }
  @media (max-width: 991.98px) {
    .header-nav__list {
      margin-right: unset !important;
      margin-left: 80px;
      margin-bottom: 15px; } }
  @media (max-width: 575.98px) {
    .header-nav__list {
      margin-left: 45px; } }
  .header-nav__link {
    font-size: 17px;
    line-height: 18px;
    color: #000;
    font-weight: 700;
    transition: 0.3s ease;
    position: relative; }
    @media (max-width: 991.98px) {
      .header-nav__link {
        color: #fff;
        font-weight: 300;
        font-size: 22px;
        line-height: 24px; } }
    @media (max-width: 575.98px) {
      .header-nav__link {
        font-size: 17px;
        line-height: 18px; } }
    .header-nav__link:hover::after {
      opacity: 1; }
    .header-nav__link_active:after {
      opacity: 1 !important; }
    .header-nav__link:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -18px;
      left: 0;
      width: 100%;
      height: 6px;
      background: #f8ab16;
      opacity: 0;
      transition: 0.3s ease; }
      @media (max-width: 991.98px) {
        .header-nav__link:after {
          bottom: 0;
          left: -80px;
          width: 14px;
          height: 25px; } }
      @media (max-width: 575.98px) {
        .header-nav__link:after {
          left: -45px;
          width: 11px;
          height: 18px; } }
  .header-nav__social {
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px; }
    .header-nav__social_hidden {
      display: none !important; }
      @media (max-width: 1199.98px) {
        .header-nav__social_hidden {
          display: flex !important;
          justify-content: center;
          margin-bottom: 12px; } }
    @media (max-width: 1199.98px) {
      .header-nav__social {
        display: none; } }
    .header-nav__social-icon1 {
      margin-bottom: 3px;
      margin-right: 10px;
      width: 30px; }
      @media (max-width: 575.98px) {
        .header-nav__social-icon1 {
          width: 26px;
          height: 15.4px; } }
    .header-nav__social-icon2 {
      margin-right: 7px;
      width: 27px; }
      @media (max-width: 575.98px) {
        .header-nav__social-icon2 {
          width: 20px; } }
    .header-nav__social-icon3 {
      width: 26px; }
      @media (max-width: 575.98px) {
        .header-nav__social-icon3 {
          width: 19px; } }

@media (max-width: 1199.98px) {
  .intro {
    margin-bottom: 120px; } }

@media (max-width: 991.98px) {
  .intro {
    margin-bottom: 100px; } }

@media (max-width: 575.98px) {
  .intro {
    margin-bottom: 70px; } }

.intro-slider {
  position: relative;
  margin-bottom: 35px; }
  .intro-slider__link {
    display: block !important; }
    .intro-slider__link img {
      width: 100%; }
  .intro-slider .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    background: none;
    outline: none;
    border: none;
    cursor: pointer; }
    @media (max-width: 1199.98px) {
      .intro-slider .slick-arrow {
        top: unset;
        bottom: -90px;
        transform: unset; } }
    @media (max-width: 991.98px) {
      .intro-slider .slick-arrow {
        bottom: -70px; } }
    @media (max-width: 575.98px) {
      .intro-slider .slick-arrow {
        bottom: -45px; } }
  .intro-slider .prev-btn {
    left: -37px; }
    @media (max-width: 1199.98px) {
      .intro-slider .prev-btn {
        left: 40%; } }
    @media (max-width: 991.98px) {
      .intro-slider .prev-btn img {
        width: 55px;
        height: 55px; } }
    @media (max-width: 575.98px) {
      .intro-slider .prev-btn {
        left: 35%; }
        .intro-slider .prev-btn img {
          width: 35px;
          height: 35px; } }
  .intro-slider .next-btn {
    right: -37px; }
    @media (max-width: 1199.98px) {
      .intro-slider .next-btn {
        right: 40%; } }
    @media (max-width: 991.98px) {
      .intro-slider .next-btn img {
        width: 55px;
        height: 55px; } }
    @media (max-width: 575.98px) {
      .intro-slider .next-btn {
        right: 35%; }
        .intro-slider .next-btn img {
          width: 35px;
          height: 35px; } }

.about {
  margin-bottom: 127px;
  position: relative; }
  @media (max-width: 991.98px) {
    .about {
      margin-bottom: 45px; } }
  .about-container {
    position: relative; }
  .about-line {
    position: absolute;
    width: 100%;
    height: 100%; }
    .about-line__blue {
      position: absolute;
      width: 28px;
      height: 567px;
      top: 0;
      left: 0; }
      @media (max-width: 991.98px) {
        .about-line__blue {
          width: 19px;
          height: 402px; } }
      @media (max-width: 575.98px) {
        .about-line__blue {
          width: 14px;
          height: 297px; } }
      @media (max-width: 365px) {
        .about-line__blue {
          width: 8px;
          height: 181px; } }
    .about-line__orange {
      position: absolute;
      width: 438px;
      height: 35px;
      bottom: 0;
      left: 105px; }
      @media (max-width: 991.98px) {
        .about-line__orange {
          width: 288px;
          height: 24px;
          left: 50px; } }
      @media (max-width: 575.98px) {
        .about-line__orange {
          width: 220px;
          height: 18px; } }
      @media (max-width: 365px) {
        .about-line__orange {
          width: 166px;
          height: 9px;
          left: 0; } }
  .about-img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: -1;
    border-radius: 5px;
    overflow: hidden; }
    .about-img-desktop {
      display: block; }
      @media (max-width: 575.98px) {
        .about-img-desktop {
          display: none; } }
    .about-img-mobile {
      display: none; }
      @media (max-width: 575.98px) {
        .about-img-mobile {
          display: block; } }
    @media (max-width: 575.98px) {
      .about-img {
        min-height: 348px;
        object-fit: cover;
        object-position: 0 0; } }
  .about-blog {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 70px 100px; }
    @media (max-width: 991.98px) {
      .about-blog {
        padding: 50px 70px; } }
    @media (max-width: 845px) {
      .about-blog {
        padding: 30px 50px; } }
    @media (max-width: 365px) {
      .about-blog {
        padding: 18px 38px; } }
    .about-blog__icon {
      width: 31px;
      margin-right: 25px; }
      @media (max-width: 767.98px) {
        .about-blog__icon {
          width: 25px;
          margin-right: 10px; } }
      .about-blog__icon_last {
        width: 19px !important;
        margin-left: 8px;
        margin-right: 28px !important; }
        @media (max-width: 767.98px) {
          .about-blog__icon_last {
            width: 15px !important;
            margin-left: 5px;
            margin-right: 13px !important; } }
    .about-blog__title h1 {
      font-size: 64px;
      line-height: 66px;
      font-weight: 700;
      margin-bottom: 75px; }
      @media (max-width: 1199.98px) {
        .about-blog__title h1 {
          font-size: 50px;
          line-height: 52px;
          margin-bottom: 60px; } }
      @media (max-width: 991.98px) {
        .about-blog__title h1 {
          font-size: 45px;
          line-height: 47px;
          margin-bottom: 40px; } }
      @media (max-width: 845px) {
        .about-blog__title h1 {
          font-size: 35px;
          line-height: 37px;
          margin-bottom: 30px; } }
      @media (max-width: 767.98px) {
        .about-blog__title h1 {
          font-size: 25px;
          line-height: 27px;
          margin-bottom: 16px; } }
      @media (max-width: 575.98px) {
        .about-blog__title h1 {
          font-size: 22px;
          line-height: 24px;
          margin-bottom: 25px; } }
      @media (max-width: 365px) {
        .about-blog__title h1 {
          font-size: 19px;
          line-height: 20px;
          margin-bottom: 20px; } }
    .about-blog__item {
      display: flex;
      align-items: flex-start; }
      .about-blog__item:not(:last-child) {
        margin-bottom: 55px; }
        @media (max-width: 1199.98px) {
          .about-blog__item:not(:last-child) {
            margin-bottom: 30px; } }
        @media (max-width: 657px) {
          .about-blog__item:not(:last-child) {
            margin-bottom: 15px; } }
        @media (max-width: 575.98px) {
          .about-blog__item:not(:last-child) {
            margin-bottom: 20px !important; } }
      .about-blog__item img {
        display: block; }
    .about-blog__inner-title h2 {
      font-size: 30px;
      line-height: 31px;
      font-weight: 400;
      margin-bottom: 12px; }
      @media (max-width: 1199.98px) {
        .about-blog__inner-title h2 {
          font-size: 28px;
          line-height: 28px; } }
      @media (max-width: 991.98px) {
        .about-blog__inner-title h2 {
          font-size: 25px;
          line-height: 25px;
          margin-bottom: 7px; } }
      @media (max-width: 767.98px) {
        .about-blog__inner-title h2 {
          font-size: 22px;
          line-height: 22px;
          margin-bottom: 5px; } }
      @media (max-width: 575.98px) {
        .about-blog__inner-title h2 {
          font-size: 16px;
          line-height: 18px; } }
      @media (max-width: 365px) {
        .about-blog__inner-title h2 {
          font-size: 13px;
          line-height: 14px; } }
    .about-blog__inner-text {
      font-size: 16px;
      line-height: 20px; }
      @media (max-width: 767.98px) {
        .about-blog__inner-text {
          font-size: 15px;
          line-height: 17px; } }
      @media (max-width: 575.98px) {
        .about-blog__inner-text {
          font-size: 10px;
          line-height: 12px; } }
      @media (max-width: 365px) {
        .about-blog__inner-text {
          font-size: 8px;
          line-height: 9px; } }
    .about-blog__icon2 {
      margin-right: 20px; }
      @media (max-width: 767.98px) {
        .about-blog__icon2 {
          margin-right: 10px; } }

.price {
  padding-bottom: 80px; }
  @media (max-width: 575.98px) {
    .price {
      padding-bottom: 65px; } }
  .price-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px; }
    .price-wrapper__img {
      display: none; }
      @media (max-width: 991.98px) {
        .price-wrapper__img {
          width: 250px;
          display: block; } }
      @media (max-width: 575.98px) {
        .price-wrapper__img {
          width: 137px; } }
  .price-blog {
    max-width: 1065px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3; }
    @media (max-width: 991.98px) {
      .price-blog {
        flex-direction: column; } }
    .price-blog__title h2 {
      font-size: 48px;
      line-height: 52px;
      font-style: italic;
      font-weight: 400;
      margin-bottom: 45px; }
      @media (max-width: 991.98px) {
        .price-blog__title h2 {
          text-align: center;
          font-size: 35px;
          line-height: 38px;
          margin-bottom: 25px; } }
      @media (max-width: 575.98px) {
        .price-blog__title h2 {
          font-size: 25px;
          line-height: 27px;
          margin-bottom: 15px; } }
      .price-blog__title h2 span {
        display: block;
        font-size: 51px;
        line-height: 52px;
        font-weight: 800;
        margin-top: 12px; }
        @media (max-width: 991.98px) {
          .price-blog__title h2 span {
            font-size: 45px;
            line-height: 47px; } }
        @media (max-width: 575.98px) {
          .price-blog__title h2 span {
            font-size: 26px;
            line-height: 27px;
            margin-top: 6px; } }
    .price-blog__content {
      margin-top: 45px; }
      @media (max-width: 991.98px) {
        .price-blog__content {
          margin-top: 0; } }
    .price-blog__text {
      font-size: 29px;
      line-height: 33px;
      margin-bottom: 35px; }
      @media (max-width: 991.98px) {
        .price-blog__text {
          font-size: 25px;
          line-height: 27px;
          margin-bottom: 20px; } }
      @media (max-width: 575.98px) {
        .price-blog__text {
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 10px; } }
    .price-blog__subtext {
      font-size: 16px;
      line-height: 18px; }
      @media (max-width: 575.98px) {
        .price-blog__subtext {
          font-size: 8px;
          line-height: 9px; } }
      .price-blog__subtext a {
        color: #000;
        text-decoration: underline; }
    .price-blog__form {
      border-radius: 10px;
      border: 9px solid transparent;
      box-shadow: 0 0 0 2px #ffc71c; }
      @media (max-width: 575.98px) {
        .price-blog__form {
          border: 5px solid transparent; } }
      .price-blog__form-inner {
        background: rgba(0, 97, 243, 0.89);
        border-radius: 10px;
        min-height: 411px;
        max-width: 456px;
        width: 100%;
        padding: 37px 89px 26px 84px; }
        @media (max-width: 575.98px) {
          .price-blog__form-inner {
            max-width: 300px;
            min-height: 235px;
            padding: 30px 50px; } }
      .price-blog__form-text {
        font-size: 29px;
        line-height: 33px;
        color: #fff;
        font-weight: 700;
        margin-bottom: 40px; }
        @media (max-width: 575.98px) {
          .price-blog__form-text {
            font-size: 18px;
            line-height: 19px;
            margin-bottom: 20px;
            text-align: center; } }
    .price-blog__input {
      display: block;
      width: 100%;
      height: 54px;
      border: 1px solid #ffb017;
      border-radius: 10px;
      margin-bottom: 22px;
      padding: 16px;
      font-size: 16px;
      line-height: 18px;
      color: #000;
      font-weight: 400; }
      @media (max-width: 575.98px) {
        .price-blog__input {
          font-size: 11px;
          line-height: 12px;
          height: 33px;
          padding: 10px;
          margin-bottom: 12px; } }
      .price-blog__input::placeholder {
        font: 400px 16px "Lato";
        color: #000;
        font-size: 16px;
        line-height: 18px; }
        @media (max-width: 575.98px) {
          .price-blog__input::placeholder {
            font-size: 11px;
            line-height: 12px; } }
    .price-blog__btn {
      width: 100%;
      height: 65px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
      border: none;
      font-size: 24px;
      color: #000;
      font-weight: 700;
      background: linear-gradient(#ffe711, #ff9b11); }
      @media (max-width: 575.98px) {
        .price-blog__btn {
          margin-top: 11px;
          font-size: 11px;
          padding: 8px 10px 9px;
          height: 30px; } }
    .price-blog__img-phone {
      position: absolute;
      z-index: -1;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      max-width: 472px;
      width: 100%; }
      @media (max-width: 991.98px) {
        .price-blog__img-phone {
          display: none; } }
    .price-blog__img-arrow {
      position: absolute;
      z-index: -1;
      top: 14px;
      left: 265px;
      width: 302px; }
      @media (max-width: 991.98px) {
        .price-blog__img-arrow {
          display: none; } }

.okni {
  position: relative;
  padding-bottom: 75px; }
  @media (max-width: 575.98px) {
    .okni {
      padding-bottom: 25px; } }
  .okni-bg {
    position: absolute;
    top: 550px;
    left: 0;
    z-index: -1;
    width: 100%; }
    @media (max-width: 1199.98px) {
      .okni-bg {
        display: none; } }
  .okni-blog {
    z-index: 3;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (max-width: 1199.98px) {
      .okni-blog {
        justify-content: center; } }
    .okni-blog img {
      display: block; }
    .okni-blog__items {
      padding: 75px 30px 45px 53px;
      max-width: 550px;
      width: 100%;
      min-height: 705px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      background: #fff;
      margin-bottom: 64px; }
      @media (max-width: 575.98px) {
        .okni-blog__items {
          max-width: 300px;
          padding: 35px 14px 25px;
          min-height: unset;
          margin-bottom: 30px; } }
    .okni-blog__items2 {
      margin-bottom: 32px; }
    .okni-blog__items3 {
      max-width: 100%;
      padding: 34px 49px 44px 39px;
      margin-bottom: 33px; }
      @media (max-width: 1199.98px) {
        .okni-blog__items3 {
          max-width: 550px;
          min-height: unset; } }
    .okni-blog__items4 {
      padding-top: 67px; }
      .okni-blog__items4 .okni-blog__img {
        margin-bottom: 100px; }
        @media (max-width: 575.98px) {
          .okni-blog__items4 .okni-blog__img {
            margin-bottom: 40px; } }
    .okni-blog__img {
      margin-bottom: 60px; }
      @media (max-width: 575.98px) {
        .okni-blog__img {
          max-width: 255px;
          margin: 0 auto 40px; } }
    .okni-blog__img2 {
      margin-left: -15px; }
    .okni-blog__img3 {
      margin-left: -22px; }
      @media (max-width: 575.98px) {
        .okni-blog__img3 {
          margin-bottom: 55px; } }
    .okni-blog__img4 {
      margin-bottom: 38px;
      margin-left: 20px; }
      @media (max-width: 1199.98px) {
        .okni-blog__img4 {
          width: 100%;
          margin-left: 0; } }
      @media (max-width: 575.98px) {
        .okni-blog__img4 {
          margin-bottom: 25px; } }
    .okni-blog__img5 {
      margin-left: -9px;
      margin-bottom: 70px; }
    .okni-blog__title h2 {
      font-size: 30px;
      line-height: 31px;
      border-bottom: 2px solid #000;
      font-weight: 700;
      display: inline-block;
      margin-bottom: 28px; }
      @media (max-width: 575.98px) {
        .okni-blog__title h2 {
          font-size: 17px;
          line-height: 18px;
          margin-bottom: 10px; } }
    .okni-blog__title2 h2 {
      font-style: italic;
      border-bottom: none;
      text-transform: uppercase; }
    .okni-blog__title3 h2 {
      margin-bottom: 90px; }
      @media (max-width: 575.98px) {
        .okni-blog__title3 h2 {
          margin-bottom: 20px; } }
    .okni-blog__title4 h2 {
      margin-bottom: 57px; }
      @media (max-width: 575.98px) {
        .okni-blog__title4 h2 {
          margin-bottom: 30px; } }
    .okni-blog__title5 h2 {
      margin-bottom: 28px; }
    .okni-blog__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .okni-blog__price {
      display: flex;
      align-items: center;
      font-size: 30px;
      line-height: 31px;
      font-weight: 700;
      margin-left: 7px; }
      @media (max-width: 575.98px) {
        .okni-blog__price {
          font-size: 17px;
          line-height: 17px; } }
    .okni-blog__icon {
      display: block;
      margin-right: 10px;
      width: 31px; }
      @media (max-width: 575.98px) {
        .okni-blog__icon {
          width: 17px;
          margin-right: 5px;
          margin-top: -2px; } }
    .okni-blog__btn {
      display: inline-block;
      text-align: center;
      width: 207px;
      height: 48px;
      font-size: 17px;
      font-weight: 700;
      background: linear-gradient(#ffe711, #ff9b11);
      color: #000;
      border-radius: 3px;
      padding: 15px;
      margin-right: 24px; }
      @media (max-width: 575.98px) {
        .okni-blog__btn {
          width: 112px;
          height: 26px;
          font-size: 10px;
          line-height: 11px;
          padding: 8px 10px 6px; } }
    .okni-blog__btn2 {
      margin-right: 8px; }
    .okni-blog__inner {
      display: flex;
      justify-content: space-between; }
      @media (max-width: 1199.98px) {
        .okni-blog__inner {
          justify-content: center; } }
    .okni-blog__text {
      max-width: 505px;
      width: 100%;
      text-align: right; }
      .okni-blog__text span {
        display: block; }
        .okni-blog__text span:first-child {
          margin-bottom: 20px; }
      @media (max-width: 1199.98px) {
        .okni-blog__text_shown {
          display: none; } }
      .okni-blog__text_hidden {
        display: none; }
        @media (max-width: 1199.98px) {
          .okni-blog__text_hidden {
            display: block;
            text-align: left;
            margin-bottom: 10px; }
            .okni-blog__text_hidden span:first-child {
              margin-bottom: 10px; } }
    .okni-blog__text2 {
      margin-bottom: 30px; }
      @media (max-width: 575.98px) {
        .okni-blog__text2 {
          margin-bottom: 20px; } }
    .okni-blog__text3 {
      margin-bottom: 37px; }
    .okni-blog__content {
      max-width: 470px;
      width: 100%; }

.okni2 .title h2 {
  margin-top: 36px;
  margin-bottom: 48px; }
  @media (max-width: 575.98px) {
    .okni2 .title h2 {
      margin-top: 25px;
      margin-bottom: 35px; } }

.property {
  padding-bottom: 70px; }
  @media (max-width: 575.98px) {
    .property {
      padding-bottom: 45px; } }
  .property .title h2 {
    margin-bottom: 42px; }
    @media (max-width: 575.98px) {
      .property .title h2 {
        margin-bottom: 39px; } }
  .property-blog {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (max-width: 1069px) {
      .property-blog {
        justify-content: space-evenly; } }
    @media (max-width: 342px) {
      .property-blog {
        justify-content: space-between; } }
    .property-blog img {
      display: block; }
    .property-blog__item {
      max-width: 260px;
      width: 100%;
      min-height: 488px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 38px 24px 21px 27px;
      margin-bottom: 30px;
      border: 1px solid #eee;
      background: #fff; }
      @media (max-width: 575.98px) {
        .property-blog__item {
          max-width: 143px;
          min-height: unset;
          padding: 20px 10px 12px;
          margin-bottom: 20px; } }
    .property-blog__item2 {
      padding-top: 31px; }
    .property-blog__item3 {
      border: none;
      box-shadow: unset; }
      @media (max-width: 1069px) {
        .property-blog__item3 {
          display: none; } }
    .property-blog__img {
      margin-left: -6px;
      margin-bottom: 49px; }
      @media (max-width: 575.98px) {
        .property-blog__img {
          width: 118px;
          margin: 0 auto 30px; } }
    .property-blog__img2 {
      margin-left: 0; }
      @media (max-width: 575.98px) {
        .property-blog__img2 {
          margin-bottom: 26px !important; } }
    .property-blog__img3 {
      margin-bottom: 36px; }
    .property-blog__img3_1 {
      margin-bottom: 34px; }
    @media (max-width: 575.98px) {
      .property-blog__img3_2 {
        margin-bottom: 34px !important; } }
    .property-blog__img4 {
      margin-left: -10px; }
    .property-blog__img5 {
      margin-left: -13px; }
    .property-blog__img6 {
      margin-bottom: 56px; }
    .property-blog__img7 {
      margin-left: -15px;
      margin-bottom: 11px; }
      @media (max-width: 575.98px) {
        .property-blog__img7 {
          margin-right: -10px;
          margin-bottom: 24px !important; } }
    .property-blog__img8 {
      margin-bottom: 41px; }
    .property-blog__img9 {
      margin-left: -12px;
      margin-bottom: 54px; }
      @media (max-width: 575.98px) {
        .property-blog__img9 {
          margin-right: -10px;
          margin-bottom: 32px !important; } }
    .property-blog__img10 {
      margin-left: -13px; }
      @media (max-width: 575.98px) {
        .property-blog__img10 {
          margin-right: -10px; } }
    .property-blog__img11 {
      margin-left: -11px;
      margin-bottom: 47px; }
      @media (max-width: 575.98px) {
        .property-blog__img11 {
          margin-right: -10px;
          margin-bottom: 29px !important; } }
    .property-blog__img12 {
      margin-left: -19px;
      margin-bottom: 54px; }
    .property-blog__img13 {
      margin-left: -11px;
      margin-bottom: 54px; }
    .property-blog__title {
      font-size: 25px;
      line-height: 27px;
      font-weight: 700;
      margin-bottom: 29px; }
      @media (max-width: 575.98px) {
        .property-blog__title {
          font-size: 13px;
          line-height: 15px;
          margin-bottom: 17px; } }
    .property-blog__title2 {
      margin-bottom: 21px; }
    .property-blog__price {
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      font-size: 24px;
      line-height: 25px;
      font-weight: 700; }
      @media (max-width: 575.98px) {
        .property-blog__price {
          font-size: 13px;
          line-height: 14px;
          margin-bottom: 13px; } }
    .property-blog__icon {
      margin-right: 8px;
      width: 31px; }
      @media (max-width: 575.98px) {
        .property-blog__icon {
          width: 13px;
          margin-right: 5px;
          margin-top: -2px; } }
    .property-blog__btn {
      display: inline-block;
      width: 100%;
      background: linear-gradient(#ffe711, #ff9b11);
      text-align: center;
      height: 48px;
      padding: 15px;
      font-size: 17px;
      font-weight: 700;
      color: #000;
      border-radius: 3px; }
      @media (max-width: 575.98px) {
        .property-blog__btn {
          max-width: 113px;
          height: 26px;
          font-size: 10px;
          padding: 8px 10px 6px; } }

.dveri {
  padding-bottom: 40px; }
  @media (max-width: 575.98px) {
    .dveri {
      padding-bottom: 34px; } }
  .dveri .title h2 {
    margin-bottom: 33px; }
  .dveri-blog {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (max-width: 1077px) {
      .dveri-blog {
        justify-content: space-evenly; } }
    @media (max-width: 345px) {
      .dveri-blog {
        justify-content: space-between; } }
    .dveri-blog img {
      display: block; }
    .dveri-blog__items {
      max-width: 355px;
      width: 100%;
      min-height: 568px;
      background: #fff;
      border: 1px solid #eee;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 30px 20px 24px 21px;
      margin-bottom: 30px; }
      @media (max-width: 575.98px) {
        .dveri-blog__items {
          max-width: 140px;
          min-height: unset;
          padding: 11px 8px;
          margin-bottom: 16px; } }
    .dveri-blog__img {
      margin-bottom: 20px; }
      @media (max-width: 575.98px) {
        .dveri-blog__img {
          width: 125px;
          margin: 0 auto 8px; } }
    .dveri-blog__title {
      font-size: 25px;
      line-height: 27px;
      font-weight: 700;
      margin-bottom: 48px;
      margin-left: 4px; }
      @media (max-width: 575.98px) {
        .dveri-blog__title {
          font-size: 10px;
          line-height: 11px;
          margin-bottom: 20px; } }
    .dveri-blog__title2 {
      margin-bottom: 22px; }
      @media (max-width: 575.98px) {
        .dveri-blog__title2 {
          margin-bottom: 9px; } }
    .dveri-blog__price {
      display: flex;
      align-items: center;
      margin-left: 4px;
      font-size: 24px;
      line-height: 25px;
      font-weight: 700;
      margin-bottom: 27px; }
      @media (max-width: 575.98px) {
        .dveri-blog__price {
          font-size: 10px;
          line-height: 11px;
          margin-bottom: 10px; } }
    .dveri-blog__icon {
      margin-right: 10px;
      width: 31px; }
      @media (max-width: 575.98px) {
        .dveri-blog__icon {
          width: 10px;
          margin-right: 5px;
          margin-top: -2px; } }
    .dveri-blog__btn {
      text-align: center;
      display: block;
      margin: 0 auto;
      width: 207px;
      height: 48px;
      background: linear-gradient(#ffe711, #ff9b11);
      padding: 15px;
      color: #000;
      border-radius: 3px;
      font-size: 17px;
      font-weight: 700; }
      @media (max-width: 575.98px) {
        .dveri-blog__btn {
          width: 83px;
          height: 18px;
          font-size: 7px;
          line-height: 8px;
          padding: 5px; } }

.promotion {
  padding-bottom: 115px; }
  @media (max-width: 575.98px) {
    .promotion {
      padding-bottom: 84px; } }
  .promotion .title h2 {
    margin-bottom: 44px; }
    @media (max-width: 575.98px) {
      .promotion .title h2 {
        margin-bottom: 20px; } }
  .promotion-blog {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 88px; }
    @media (max-width: 1199.98px) {
      .promotion-blog {
        justify-content: space-around; } }
    @media (max-width: 575.98px) {
      .promotion-blog {
        margin-bottom: 20px; } }
    .promotion-blog img {
      display: block; }
    .promotion-blog__items {
      max-width: 357px;
      width: 100%;
      min-height: 462px;
      display: flex; }
      @media (max-width: 1090px) {
        .promotion-blog__items:last-child {
          margin-top: 30px; } }
      @media (max-width: 733px) {
        .promotion-blog__items {
          margin-bottom: 30px; }
          .promotion-blog__items:last-child {
            margin-top: 0; } }
      @media (max-width: 575.98px) {
        .promotion-blog__items {
          max-width: 300px;
          min-height: 387px;
          margin-bottom: 20px; } }
    .promotion-blog__content {
      max-width: 222px;
      width: 100%;
      background: #f8f8f8;
      padding: 36px 31px 25px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      @media (max-width: 575.98px) {
        .promotion-blog__content {
          padding: 30px 31px 20px 30px;
          max-width: 186px; } }
    .promotion-blog__content2 {
      padding-right: 26px; }
    .promotion-blog__title h2 {
      font-size: 30px;
      line-height: 31px;
      color: #ffb017;
      font-weight: 700;
      margin-bottom: 35px; }
      @media (max-width: 575.98px) {
        .promotion-blog__title h2 {
          font-size: 25px;
          line-height: 26px;
          margin-bottom: 30px; } }
    .promotion-blog__text {
      font-size: 15px;
      line-height: 19px;
      font-weight: 500; }
      @media (max-width: 575.98px) {
        .promotion-blog__text {
          font-size: 12px;
          line-height: 16px; } }
      .promotion-blog__text span {
        color: #ffb017; }
    .promotion-blog__text2 {
      max-width: 142px;
      width: 100%; }
    .promotion-blog__span {
      display: block;
      margin-bottom: 20px; }
    .promotion-blog__btn {
      background: linear-gradient(#ffe711, #ff9b11);
      max-width: 163px;
      width: 100%;
      height: 48px;
      display: block;
      text-align: center;
      padding: 15px;
      font-size: 17px;
      font-weight: 700;
      color: #000;
      border-radius: 3px; }
      @media (max-width: 575.98px) {
        .promotion-blog__btn {
          max-width: 138px;
          height: 40px;
          font-size: 15px;
          padding: 13px 10px 12px; } }
    .promotion-blog__img {
      max-width: 135px;
      width: 100%;
      max-height: 462px; }
      @media (max-width: 575.98px) {
        .promotion-blog__img {
          width: 114px;
          max-height: 387px; } }
    .promotion-blog__more {
      background: linear-gradient(#ffe711, #ff9b11);
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 15px;
      padding-top: 17px;
      font-size: 17px;
      font-weight: 700;
      color: #000;
      border-radius: 3px;
      max-width: 287px;
      width: 100%;
      margin: 0 auto; }
      @media (max-width: 575.98px) {
        .promotion-blog__more {
          max-width: 228px;
          height: 38px;
          font-size: 14px;
          line-height: 15px;
          padding: 12px 10px 9px; } }

.gallery {
  padding-bottom: 155px; }
  @media (max-width: 767.98px) {
    .gallery {
      padding-bottom: 65px; } }
  .gallery-blog {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px; }
    @media (max-width: 1090px) {
      .gallery-blog {
        justify-content: space-evenly; } }
    @media (max-width: 350px) {
      .gallery-blog {
        justify-content: space-between; } }
    @media (max-width: 767.98px) {
      .gallery-blog {
        max-width: 360px;
        width: 100%;
        margin: 0 auto; } }
    .gallery-blog__link {
      margin-bottom: 30px;
      display: inline-block;
      position: relative; }
      .gallery-blog__link:hover::after {
        opacity: 1; }
      .gallery-blog__link:after {
        content: '';
        background-image: url("../img/lupa.png") !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 135px;
        height: 135px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.5s ease; }
        @media (max-width: 767.98px) {
          .gallery-blog__link:after {
            width: 55px;
            height: 55px;
            background-size: 25px auto; } }
    .gallery-blog__img {
      display: block;
      width: 356px;
      height: 361px; }
      @media (max-width: 767.98px) {
        .gallery-blog__img {
          width: 143px;
          height: 143px; } }

.sertificat {
  padding-bottom: 95px; }
  @media (max-width: 1199.98px) {
    .sertificat {
      padding-bottom: 150px; } }
  @media (max-width: 575.98px) {
    .sertificat {
      padding-bottom: 115px; } }
  .sertificat-blog {
    background: url("../img/fon-sertifikati.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 983px;
    border-radius: 5px;
    padding-top: 55px;
    position: relative; }
    @media (max-width: 767.98px) {
      .sertificat-blog {
        max-width: 600px;
        min-height: unset;
        margin: 0 auto;
        padding-top: 22px;
        padding-bottom: 15px; } }
    .sertificat-blog__title h2 {
      text-align: center;
      font-size: 63px;
      line-height: 66px;
      font-weight: 700;
      margin-bottom: 20px; }
      @media (max-width: 767.98px) {
        .sertificat-blog__title h2 {
          font-size: 23px;
          line-height: 24px; } }
    .sertificat-blog__subtitle {
      text-align: center;
      max-width: 406px;
      width: 100%;
      margin: 0 auto;
      line-height: 20px;
      margin-bottom: 65px; }
      @media (max-width: 767.98px) {
        .sertificat-blog__subtitle {
          font-size: 9px;
          line-height: 11px;
          margin-bottom: 33px; } }
      @media (max-width: 575.98px) {
        .sertificat-blog__subtitle {
          margin-bottom: 30px; } }
  .sertificat-container {
    max-width: 855px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 90px; }
    @media (max-width: 767.98px) {
      .sertificat-container {
        max-width: 500px;
        padding-bottom: 30px; } }
    @media (max-width: 575.98px) {
      .sertificat-container {
        max-width: 300px;
        padding-bottom: 0; } }
  .sertificat-slider__link {
    width: 315px !important;
    padding: 18px 26px;
    background: #fff;
    position: relative; }
    @media (max-width: 991.98px) {
      .sertificat-slider__link {
        width: 250px !important;
        padding: 10px 15px; } }
    @media (max-width: 767.98px) {
      .sertificat-slider__link {
        width: 150px !important;
        padding: 5px; } }
    @media (max-width: 575.98px) {
      .sertificat-slider__link {
        width: 100px !important; } }
    .sertificat-slider__link:hover::after {
      opacity: 1; }
    .sertificat-slider__link:after {
      content: '';
      display: block;
      width: 105px;
      height: 105px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url(../img/lupa.png) !important;
      background-repeat: no-repeat !important;
      background-position: center center !important;
      background: rgba(0, 0, 0, 0.5);
      transition: opacity 0.5s ease;
      background-size: 47px auto;
      opacity: 0; }
      @media (max-width: 767.98px) {
        .sertificat-slider__link:after {
          width: 75px;
          height: 75px;
          background-size: 30px auto; } }
      @media (max-width: 575.98px) {
        .sertificat-slider__link:after {
          width: 50px;
          height: 50px;
          background-size: 20px auto; } }
  .sertificat-slider__img {
    width: 100%; }
  .sertificat .slick-track {
    height: 385px; }
    @media (max-width: 767.98px) {
      .sertificat .slick-track {
        height: 235px; } }
    @media (max-width: 575.98px) {
      .sertificat .slick-track {
        height: 150px; } }
  .sertificat .slick-center {
    transform: scale(1.3);
    margin-top: 75px !important; }
    @media (max-width: 767.98px) {
      .sertificat .slick-center {
        margin-top: 15px !important; } }
    @media (max-width: 575.98px) {
      .sertificat .slick-center {
        margin-top: 10px !important; } }
    .sertificat .slick-center .sertificat-slider__link {
      padding: 30px 20px !important;
      z-index: 3 !important; }
      @media (max-width: 991.98px) {
        .sertificat .slick-center .sertificat-slider__link {
          padding: 20px 15px !important; } }
      @media (max-width: 767.98px) {
        .sertificat .slick-center .sertificat-slider__link {
          padding: 5px !important; } }
  .sertificat .slick-slide {
    margin-top: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1; }
    @media (max-width: 767.98px) {
      .sertificat .slick-slide {
        margin-top: 70px; } }
    @media (max-width: 575.98px) {
      .sertificat .slick-slide {
        margin-top: 45px; } }
    .sertificat .slick-slide .sertificat-slider__link {
      z-index: -1; }
  .sertificat .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer; }
    @media (max-width: 1199.98px) {
      .sertificat .slick-arrow {
        top: unset;
        bottom: -190px;
        transform: unset; } }
    @media (max-width: 991.98px) {
      .sertificat .slick-arrow {
        bottom: -160px; } }
    @media (max-width: 767.98px) {
      .sertificat .slick-arrow {
        bottom: -115px; } }
    @media (max-width: 575.98px) {
      .sertificat .slick-arrow {
        bottom: -60px; } }
  .sertificat .prev-btn {
    left: -185px; }
    @media (max-width: 1199.98px) {
      .sertificat .prev-btn {
        left: 40%; } }
    @media (max-width: 991.98px) {
      .sertificat .prev-btn img {
        width: 55px;
        height: 55px; } }
    @media (max-width: 767.98px) {
      .sertificat .prev-btn {
        left: 35%; } }
    @media (max-width: 575.98px) {
      .sertificat .prev-btn {
        left: 35%; }
        .sertificat .prev-btn img {
          width: 35px;
          height: 35px; } }
  .sertificat .next-btn {
    right: -185px; }
    @media (max-width: 1199.98px) {
      .sertificat .next-btn {
        right: 40%; } }
    @media (max-width: 991.98px) {
      .sertificat .next-btn img {
        width: 55px;
        height: 55px; } }
    @media (max-width: 767.98px) {
      .sertificat .next-btn {
        right: 35%; } }
    @media (max-width: 575.98px) {
      .sertificat .next-btn {
        right: 35%; }
        .sertificat .next-btn img {
          width: 35px;
          height: 35px; } }

.testimonials {
  padding-bottom: 90px; }
  @media (max-width: 575.98px) {
    .testimonials {
      padding-bottom: 65px; } }
  .testimonials-blog {
    padding: 60px 65px 48px 70px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border: 1px solid #eee; }
    @media (max-width: 991.98px) {
      .testimonials-blog {
        padding: 30px 35px 48px 40px; } }
    @media (max-width: 575.98px) {
      .testimonials-blog {
        padding: 23px 17px 32px 18px; } }
    .testimonials-blog__title h2 {
      font-size: 30px;
      line-height: 31px;
      font-weight: 700;
      margin-bottom: 20px; }
      @media (max-width: 575.98px) {
        .testimonials-blog__title h2 {
          font-size: 19px;
          line-height: 20px;
          margin-bottom: 16px; } }
    .testimonials-blog__content {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ffb017;
      padding-bottom: 44px;
      margin-bottom: 54px; }
      @media (max-width: 991.98px) {
        .testimonials-blog__content {
          flex-direction: column; } }
      @media (max-width: 575.98px) {
        .testimonials-blog__content {
          padding-bottom: 33px;
          margin-bottom: 21px; } }
    .testimonials-blog__text {
      max-width: 668px;
      width: 100%; }
      .testimonials-blog__text span {
        display: block; }
        .testimonials-blog__text span:first-child {
          margin-bottom: 20px; }
          @media (max-width: 575.98px) {
            .testimonials-blog__text span:first-child {
              margin-bottom: 12px; } }
      @media (max-width: 575.98px) {
        .testimonials-blog__text {
          font-size: 9px;
          line-height: 12px; } }
    .testimonials-blog__btn {
      background: linear-gradient(#ffe711, #ff9b11);
      display: inline-block;
      max-width: 282px;
      width: 100%;
      height: 65px;
      text-align: center;
      color: #000;
      padding: 24px 20px 20px;
      font-size: 23px;
      font-weight: 700;
      border-radius: 3px;
      margin-top: 7px; }
      @media (max-width: 991.98px) {
        .testimonials-blog__btn {
          margin-top: 22px; } }
      @media (max-width: 575.98px) {
        .testimonials-blog__btn {
          max-width: 134px;
          height: 31px;
          font-size: 11px;
          padding: 9px 10px 11px; } }
  .testimonials-slider__items {
    display: flex; }
    @media (max-width: 767.98px) {
      .testimonials-slider__items {
        flex-direction: column; } }
  .testimonials-slider__img {
    display: block;
    margin-right: 76px; }
    @media (max-width: 991.98px) {
      .testimonials-slider__img {
        margin-right: 20px; } }
    @media (max-width: 767.98px) {
      .testimonials-slider__img {
        margin-right: 0;
        margin-bottom: 20px; } }
    @media (max-width: 575.98px) {
      .testimonials-slider__img {
        margin-bottom: 28px; } }
  .testimonials-slider__name {
    display: flex;
    align-items: center;
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 15px; }
    @media (max-width: 575.98px) {
      .testimonials-slider__name {
        margin-top: 0;
        font-size: 12px;
        line-height: 13px;
        margin-bottom: 7px; } }
  .testimonials-slider__star {
    display: block;
    margin-bottom: 2px; }
    @media (max-width: 575.98px) {
      .testimonials-slider__star {
        width: 14px; } }
    .testimonials-slider__star:first-child {
      margin-left: 15px; }
      @media (max-width: 575.98px) {
        .testimonials-slider__star:first-child {
          margin-left: 8px; } }
  .testimonials-slider__date {
    display: flex;
    align-items: center;
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 20px; }
    @media (max-width: 575.98px) {
      .testimonials-slider__date {
        font-size: 12px;
        line-height: 13px; } }
  .testimonials-slider__calendar {
    display: block;
    margin-right: 10px;
    margin-top: -5px; }
    @media (max-width: 575.98px) {
      .testimonials-slider__calendar {
        width: 14px;
        margin-right: 5px; } }
  .testimonials-slider__text span {
    display: block;
    margin: 23px 0 17px; }
  @media (max-width: 575.98px) {
    .testimonials-slider__text {
      font-size: 10px;
      line-height: 12px; } }
  .testimonials-slider__btn {
    max-width: 212px;
    width: 100%;
    height: 40px;
    background: #ffde00;
    display: flex;
    border-radius: 20px;
    align-items: center;
    padding: 5px 5px 5px 22px;
    font-size: 17px;
    color: #000;
    font-weight: 700;
    justify-content: space-between;
    transition: 0.5s ease; }
    .testimonials-slider__btn:hover {
      background: #ff9800; }
    @media (max-width: 575.98px) {
      .testimonials-slider__btn {
        max-width: 130px;
        width: 100%;
        height: 27px;
        padding: 3px 3px 3px 13px;
        font-size: 10px; } }
    .testimonials-slider__btn_span {
      width: 32px;
      height: 32px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      background: #345edb;
      border-radius: 50%;
      margin: 0 !important; }
      @media (max-width: 575.98px) {
        .testimonials-slider__btn_span {
          width: 22px;
          height: 22px; } }
      .testimonials-slider__btn_span img {
        display: block;
        width: 20px;
        margin-right: 2px; }
        @media (max-width: 575.98px) {
          .testimonials-slider__btn_span img {
            width: 13px; } }
  .testimonials-slider .slick-slide {
    padding: 0 1px; }
  .testimonials-slider .slick-arrow {
    position: absolute;
    z-index: 3;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: none;
    cursor: pointer; }
    @media (max-width: 1199.98px) {
      .testimonials-slider .slick-arrow {
        top: unset;
        bottom: -90px;
        transform: unset; } }
    @media (max-width: 991.98px) {
      .testimonials-slider .slick-arrow {
        bottom: -80px; } }
    @media (max-width: 575.98px) {
      .testimonials-slider .slick-arrow {
        bottom: -53px; } }
  .testimonials-slider .prev-btn {
    left: -105px; }
    @media (max-width: 1199.98px) {
      .testimonials-slider .prev-btn {
        left: 40%; } }
    @media (max-width: 991.98px) {
      .testimonials-slider .prev-btn img {
        width: 55px;
        height: 55px; } }
    @media (max-width: 671px) {
      .testimonials-slider .prev-btn {
        left: 35%; } }
    @media (max-width: 575.98px) {
      .testimonials-slider .prev-btn {
        left: 35%; }
        .testimonials-slider .prev-btn img {
          width: 35px;
          height: 35px; } }
  .testimonials-slider .next-btn {
    right: -105px; }
    @media (max-width: 1199.98px) {
      .testimonials-slider .next-btn {
        right: 40%; } }
    @media (max-width: 991.98px) {
      .testimonials-slider .next-btn img {
        width: 55px;
        height: 55px; } }
    @media (max-width: 671px) {
      .testimonials-slider .next-btn {
        right: 35%; } }
    @media (max-width: 575.98px) {
      .testimonials-slider .next-btn {
        right: 35%; }
        .testimonials-slider .next-btn img {
          width: 35px;
          height: 35px; } }

.contact {
  padding-bottom: 108px; }
  @media (max-width: 575.98px) {
    .contact {
      padding-bottom: 47px; } }
  .contact-span {
    max-width: 438px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute; }
    @media (max-width: 575.98px) {
      .contact-span {
        max-width: 180px; } }
    .contact-span span {
      width: 17px;
      height: 14px;
      background: #fff;
      display: block; }
      @media (max-width: 575.98px) {
        .contact-span span {
          width: 7px;
          height: 10px; } }
  .contact-span1 {
    top: -13px;
    left: 58px; }
    @media (max-width: 575.98px) {
      .contact-span1 {
        top: -9px;
        left: 20px; } }
  .contact-span2 {
    bottom: -13px;
    right: 70px; }
    @media (max-width: 575.98px) {
      .contact-span2 {
        display: none; } }
  .contact-blog {
    width: 100%;
    height: 478px;
    border: 13px solid #345edb;
    border-radius: 10px;
    display: flex;
    position: relative; }
    @media (max-width: 1199.98px) {
      .contact-blog {
        justify-content: space-between; } }
    @media (max-width: 991.98px) {
      .contact-blog {
        flex-direction: column;
        height: auto; } }
    @media (max-width: 575.98px) {
      .contact-blog {
        border: 9px solid #345edb; } }
    .contact-blog__content {
      padding: 50px 0 0 60px; }
      @media (max-width: 1199.98px) {
        .contact-blog__content {
          padding: 30px 0 0 40px; } }
      @media (max-width: 991.98px) {
        .contact-blog__content {
          text-align: center;
          padding-left: 0; } }
      @media (max-width: 767.98px) {
        .contact-blog__content {
          padding-left: 27px;
          padding-right: 27px; } }
    .contact-blog__img {
      position: relative;
      display: block;
      height: 485px;
      margin-top: -33px; }
      @media (max-width: 991.98px) {
        .contact-blog__img {
          width: 375px;
          margin: 46px auto 0; } }
      @media (max-width: 575.98px) {
        .contact-blog__img {
          width: 192px;
          height: auto; } }
    .contact-blog__title h2 {
      font-size: 45px;
      font-weight: 800;
      font-style: italic;
      margin-bottom: 30px;
      line-height: 47px; }
      @media (max-width: 1199.98px) {
        .contact-blog__title h2 {
          font-size: 35px;
          line-height: 37px;
          text-align: center;
          margin-bottom: 25px; } }
      @media (max-width: 575.98px) {
        .contact-blog__title h2 {
          font-size: 27px;
          line-height: 30px; } }
    .contact-blog__subtitle {
      font-size: 23px;
      line-height: 27px;
      font-weight: 400;
      margin-bottom: 40px; }
      @media (max-width: 575.98px) {
        .contact-blog__subtitle {
          font-size: 17px;
          line-height: 20px;
          margin-bottom: 23px; }
          .contact-blog__subtitle br {
            display: none; } }
    .contact-blog__top {
      display: flex;
      margin-bottom: 37px; }
      @media (max-width: 991.98px) {
        .contact-blog__top {
          justify-content: center; } }
      @media (max-width: 767.98px) {
        .contact-blog__top {
          flex-direction: column;
          align-items: center; } }
      @media (max-width: 575.98px) {
        .contact-blog__top {
          margin-bottom: 23px; } }
      .contact-blog__top input {
        max-width: 280px;
        width: 100%;
        height: 54px;
        padding: 17px;
        border: 1px solid #ffb017;
        border-radius: 10px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
        color: #000; }
        @media (max-width: 767.98px) {
          .contact-blog__top input {
            max-width: 520px; } }
        @media (max-width: 575.98px) {
          .contact-blog__top input {
            height: 47px;
            font-size: 14px; } }
        .contact-blog__top input:first-child {
          margin-right: 33px; }
          @media (max-width: 767.98px) {
            .contact-blog__top input:first-child {
              margin-right: 0;
              margin-bottom: 27px; } }
        .contact-blog__top input::placeholder {
          font: 16px solid 'Lato';
          color: #000;
          font-weight: 400; }
          @media (max-width: 575.98px) {
            .contact-blog__top input::placeholder {
              font-size: 14px; } }
    .contact-blog__bottom {
      display: flex;
      align-items: center; }
      @media (max-width: 991.98px) {
        .contact-blog__bottom {
          justify-content: center; } }
      @media (max-width: 575.98px) {
        .contact-blog__bottom {
          flex-direction: column-reverse; } }
    .contact-blog__btn {
      max-width: 282px;
      width: 100%;
      height: 65px;
      text-align: center;
      background: linear-gradient(#ffe711, #ff9b11);
      padding: 15px;
      border-radius: 3px;
      border: none;
      cursor: pointer;
      font-size: 23px;
      font-weight: 700;
      margin-right: 28px; }
      @media (max-width: 767.98px) {
        .contact-blog__btn {
          margin-right: 0; } }
      @media (max-width: 575.98px) {
        .contact-blog__btn {
          margin-right: 0;
          max-width: 232px;
          height: 53px;
          padding: 10px 10px 12px;
          font-size: 20px; } }
    .contact-blog__text a {
      color: #000;
      text-decoration: underline; }
    @media (max-width: 767.98px) {
      .contact-blog__text br {
        display: none; } }
    @media (max-width: 575.98px) {
      .contact-blog__text {
        max-width: 195px;
        width: 100%;
        margin-bottom: 25px; } }

.footer .title {
  text-align: left; }
  @media (max-width: 575.98px) {
    .footer .title h2 {
      margin-bottom: 30px; } }

@media (max-width: 991.98px) {
  .footer .container {
    padding: 0 !important; } }

@media (max-width: 767.98px) {
  .footer iframe {
    height: 400px; } }

@media (max-width: 575.98px) {
  .footer iframe {
    height: 304px; } }

.footer-inner {
  background: #345edb;
  position: relative;
  height: 517px; }
  @media (max-width: 991.98px) {
    .footer-inner {
      height: auto; } }

.footer-wrapper {
  display: flex; }
  @media (max-width: 991.98px) {
    .footer-wrapper {
      flex-direction: column-reverse; } }

.footer-blog {
  width: 41%;
  background: #345edb;
  color: #fff; }
  @media (max-width: 991.98px) {
    .footer-blog {
      width: 100%;
      padding-left: 30px;
      padding-bottom: 45px; } }
  .footer-blog__content {
    padding-top: 50px; }
    .footer-blog__content-inner {
      position: relative; }
    @media (max-width: 575.98px) {
      .footer-blog__content {
        padding-top: 43px; } }
    .footer-blog__content .title h2 {
      margin-bottom: 50px; }
  .footer-blog__text {
    max-width: 350px;
    width: 100%;
    margin-bottom: 15px; }
    @media (max-width: 575.98px) {
      .footer-blog__text {
        font-size: 10px;
        line-height: 11px;
        margin-bottom: 12px; } }
    .footer-blog__text span {
      display: block;
      margin-bottom: 15px;
      font-weight: 700; }
      @media (max-width: 575.98px) {
        .footer-blog__text span {
          margin-bottom: 12px; } }
  .footer-blog__rotangle {
    position: absolute;
    bottom: -60px;
    left: 0;
    display: flex;
    justify-content: space-between;
    max-width: 88%;
    width: 100%; }
    @media (max-width: 991.98px) {
      .footer-blog__rotangle {
        max-width: 400px; } }
    @media (max-width: 575.98px) {
      .footer-blog__rotangle {
        max-width: 225px; } }
    .footer-blog__rotangle span {
      width: 17px;
      height: 25px;
      background: #ffc000;
      display: block; }
      @media (max-width: 575.98px) {
        .footer-blog__rotangle span {
          width: 9px;
          height: 13px; } }

.footer-map {
  width: 59%;
  position: absolute;
  top: 0;
  right: 0; }
  @media (max-width: 991.98px) {
    .footer-map {
      position: static;
      width: 100%; } }

.footer-copy {
  background: #f8f8f8;
  padding: 24px 0;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.15);
  position: relative; }
  @media (max-width: 991.98px) {
    .footer-copy {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (max-width: 575.98px) {
    .footer-copy {
      padding-top: 15px;
      padding-bottom: 15px; } }
  .footer-copy__text {
    display: block; }
  .footer-copy-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 767.98px) {
      .footer-copy-wrapper {
        flex-direction: column; } }
    @media (max-width: 575.98px) {
      .footer-copy-wrapper {
        font-size: 8px;
        line-height: 11px; } }

/* GALLERY PAGE */
.gallery-page {
  padding-top: 70px;
  padding-bottom: 80px; }
  @media (max-width: 575.98px) {
    .gallery-page {
      padding: 50px 0; } }
  .gallery-page .gallery-blog {
    margin-bottom: 50px; }
    @media (max-width: 767.98px) {
      .gallery-page .gallery-blog {
        margin-bottom: 0; } }

.contact-page {
  position: relative;
  z-index: 3; }
  .contact-page__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    object-fit: contain;
    z-index: -1; }
    @media (max-width: 1199.98px) {
      .contact-page__bg {
        display: none; } }

/* Promotion page */
.promotion-page {
  padding-top: 70px;
  padding-bottom: 120px; }
  @media (max-width: 575.98px) {
    .promotion-page {
      padding: 50px 0; } }
  .promotion-page .promotion-blog {
    margin-bottom: 0; }
  .promotion-page .promotion-blog__items {
    margin-bottom: 30px; }

.header-page {
  display: flex;
  justify-content: center; }

.thankpage {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 300px; }
  @media (max-width: 575.98px) {
    .thankpage {
      padding-top: 0; } }
  .thankpage-img {
    width: 154px;
    margin-bottom: 60px;
    display: block;
    margin: 0 auto 60px; }
    @media (max-width: 575.98px) {
      .thankpage-img {
        width: 120px;
        margin-bottom: 35px; } }
  .thankpage-title h2 {
    font-size: 30px;
    line-height: 31px;
    font-weight: 700;
    margin-bottom: 30px; }
    @media (max-width: 575.98px) {
      .thankpage-title h2 {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 20px; } }
  .thankpage-text {
    font-size: 19px;
    line-height: 23px;
    margin-bottom: 50px; }
    @media (max-width: 575.98px) {
      .thankpage-text {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 45px; } }
  .thankpage-btn {
    text-align: center;
    background: linear-gradient(#ffe711, #ff9b11);
    border-radius: 3px;
    width: 207px;
    height: 48px;
    display: inline-block;
    font-size: 14px;
    line-height: 15px;
    padding: 15px;
    color: #000;
    font-weight: 700;
    padding-top: 18px; }
    @media (max-width: 575.98px) {
      .thankpage-btn {
        width: 175px;
        height: 37px;
        font-size: 9px;
        line-height: 15px;
        padding: 12px 15px 15px; } }

.error-img {
  width: 233px; }

input.error,
textarea.error {
  border-color: red !important;
  background: #ffe9e9 !important; }
